<template>
  <div class="jumbotron" v-if="isMounted">
    <b-container class="activity-detail mt-4 " v-if="detail != null">
      <h2 class="blue">{{ detail.title }}</h2>
      <div class="content">
        <!-- <b-list-group>
        <b-list-group-item>{{ $t('日期') }}：{{ detail.dateTxt != null && detail.dateTxt != '' ? detail.dateTxt : detail.date }}</b-list-group-item>
        <b-list-group-item>
          {{ $t('地點') }}：<span v-html="detail.place" style="display:inline-block"></span>
        </b-list-group-item>
      </b-list-group> -->
        <div class="col-12 col-sm-12 col-md-12 p-0 my-3">
          <b-img-lazy
            :src="detail.banner ? detail.banner : require('@/assets/images/s_banner0.png')"
            :alt="detail.title"
            style="max-height:200px;    object-fit: cover;"
          />
        </div>
        <ul class="summary">
          <li class="dot">
            {{ $t('日期') }}：{{ detail.dateTxt != null && detail.dateTxt != '' ? detail.dateTxt : detail.date }}
          </li>
          <li class="dot" v-if="detail.place">
            <b-row>
              <b-col class="col-auto" style="padding-right: 0px">{{ $t('地點') }}：</b-col>
              <b-col v-html="detail.place" style="padding-left: 0px"> </b-col>
            </b-row>
          </li>
        </ul>
        <div class="info">
          <div class="text" v-html="detail.summary"></div>
        </div>
        <div class="picture-list" v-if="detail.calendarPhotos.length > 0">
          <div class="arrow_left activity-swiper-button-prev" title="prev"></div>
          <div class="arrow_right activity-swiper-button-next" title="next"></div>
          <ul id="servicesScroll" class="d-flex">
            <swiper ref="mySwiper" :options="activitySwiperOptions">
              <swiper-slide v-for="(img, index) in detail.calendarPhotos" :key="img.url + img.title + index">
                <b-img :src="img.image" :alt="img.title" />
              </swiper-slide>
            </swiper>
            <!-- <li v-for="(img,index) in detail.calendarPhotos" :key="img.url + img.title +index">
            <b-img :src="`${require('@/assets/images/s_banner3.png')}`" :alt="img.title" />
          </li> -->
          </ul>
        </div>
        <div class="info">
          <div class="text" v-html="detail.content"></div>
        </div>
        <!-- <b-row class="btn-box my-4" align-h="center">
        <b-button class="btn"
                  pill>
          <b-link rel="" :to="{name: 'activity-table-form', params: { title: detail.title }}">{{$t('參加表格')}}</b-link>
        </b-button>
      </b-row> -->
        <template v-if="detail.news">
          <div class="news-list my-5" v-if="detail.news.length > 0">
            <h4 class="my-4">{{ $t('相關新聞') }}</h4>
            <ul class="d-flex flex-wrap mx-0">
              <li class="col-12 col-md-6 p-3" v-for="newsList in detail.news" :key="`newslist-${newsList.id}`">
                <b-link
                  :href="newsList.url ? newsList.url : ''"
                  :to="
                    newsList.url
                      ? ''
                      : {
                          name: 'news-detail',
                          params: { id: newsList.id }
                        }
                  "
                  :target="newsList.newWindow ? '_blank' : ''"
                >
                  <div class="d-flex flex-wrap pb-3 content_title align-items-center content_item">
                    <div class="col-12 col-md-2 p-0">
                      <b-img
                        :src="newsList.cover ? newsList.cover : `${require('@/assets/images/s_banner0.png')}`"
                        :alt="newsList.title"
                      />
                    </div>
                    <div class="col-12 col-md-10 my-auto item_text">
                      <p class="blue">{{ newsList.inputAt }}</p>
                      <p class="sub-title">{{ newsList.title }}</p>
                    </div>
                  </div>
                </b-link>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  mixins: [Lifecycle],
  data() {
    return {
      isMounted: false,
      detail: null,
      activitySwiperOptions: {
        slidesPerView: 4,
        slidesPerColumnFill: 'row',
        spaceBetween: 20,
        updateOnWindowResize: true,
        observer: true,
        observeParents: true,
        setWrapperSize: true,
        grabCursor: true,
        breakpoints: {
          768: {
            slidesPerView: 1
          },
          900: {
            slidesPerView: 2
          },
          1200: {
            slidesPerView: 3
          }
        },
        navigation: {
          nextEl: '.activity-swiper-button-next',
          prevEl: '.activity-swiper-button-prev'
        }
      }
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  computed: {
    ...mapState('common', ['page']),
    ...mapState('page', ['activity'])
  },
  mounted() {
    this.fetchData()
    this.isMounted = true
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = this.$route.name
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid } = this.$route.query
      console.log(path)

      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_ACTIVITY_DETAIL', {
            cookies,
            preview,
            id: this.$route.params.id || id,
            vid
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    async hanldeInit() {
      if (await this.activity) {
        this.detail = this.activity
      } else {
        console.log(this.activity, this.page)
        // this.$router.push({
        //   name: 'home'
        // })
      }
    },
    scrollLeft(id) {
      document.getElementById(id).scrollLeft -= 300
    },
    scrollRight(id) {
      document.getElementById(id).scrollLeft += 300
    }
  }
}
</script>
